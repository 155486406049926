<template>
  <section class="tagesbericht-add-wrapper">
    <b-row class="tagesbericht-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="tagesbericht-preview-card">
            <!-- Header -->
            <b-card-body class="tagesbericht-padding pb-0">
              <b-row
                class="d-flex justify-content-between flex-md-row flex-column tagesbericht-spacing mt-0 mb-0"
              >
                <!-- Header: Left Content -->
                <b-col cols="4">
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary tagesbericht-logo">
                      <span>Tempo</span>Ratio
                    </h3>
                  </div>
                  <p class="card-text mb-25">Carl-Benz-Straße 3</p>
                  <p class="card-text mb-25">68723 Schwetzingen</p>
                  <p class="card-text mb-25">Tel.: 06202 / 859349-0</p>
                  <p class="card-text mb-25">Fax: 06202 / 859349-9</p>
                  <p class="card-text mb-25">
                    Email: pruefergebnis@temporatio.de
                  </p>
                  <p class="card-text mb-25">Webseite: www.temporatio.de</p>
                </b-col>

                <!-- Header: Right Content -->
                <b-col cols="4" class="tagesbericht-number-date mt-md-0 mt-2">
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-50"
                  >
                    <h5 class="mr-1">Auftragsnummer:</h5>
                    <h5>#{{ orderData.Auftragsnummer }}</h5>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-50"
                  >
                    <h5 class="mr-1">Auftragsstatus:</h5>
                    <b-badge :variant="auftragsstatus.color" class="mb-50">
                      {{ auftragsstatus.label }}
                    </b-badge>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-50"
                  >
                    <h5 class="mr-1">Berichtnummer:</h5>
                    <b-input-group
                      class="input-group-merge tagesbericht-edit-input-group disabled"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        class="pl-1"
                        id="tagesbericht-data-id"
                        v-model="berichtData.Tagesberichtnummer"
                      />
                    </b-input-group>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-50"
                  >
                    <h5 class="">Auftraggeber:</h5>
                    <h5 class="text-right">{{ orderData.Kunde.Firma }}</h5>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-50"
                  >
                    <h5 class="">Auftragsort:</h5>
                    <h5>
                      {{ auftragsort }}
                    </h5>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-md-between mb-50"
                  >
                    <h5 class="">Ansprechpartner:</h5>
                    <h5>
                      {{ ansprechpartner.Vorname }}
                      {{ ansprechpartner.Nachname }}
                    </h5>
                  </div>
                </b-col>
                <b-col cols="12" class="mt-2">
                  <b-row>
                    <b-col cols="12" lg="4">
                      <b-form-group
                        label="Art des Berichts"
                        label-for="art-des-berichts"
                      >
                        <v-select
                          v-model="berichtData.Bericht_Art"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="[
                            'Tagesbericht',
                            'Bericht',
                            'Abschlussbericht',
                          ]"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Art des Berichts auswählen"
                          id="art-des-berichts"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="4">
                      <b-form-group label="Startdatum" label-for="startdatum">
                        <b-form-datepicker
                          id="startdatum"
                          v-model="berichtData.Startdatum"
                          v-bind="{
                            labelPrevDecade: 'Vorheriges Jahrzehnt',
                            labelPrevYear: 'Vorheriges Jahr',
                            labelPrevMonth: 'Vorheriger Monat',
                            labelCurrentMonth: 'Aktueller Monat',
                            labelNextMonth: 'Nächster Monat',
                            labelNextYear: 'Nächstes Jahr',
                            labelNextDecade: 'Nächstes Jahrzehnt',
                            labelToday: 'Heute',
                            labelSelected: 'Ausgewähltes Datum',
                            labelNoDateSelected: 'Kein Datum gewählt',
                            labelCalendar: 'Kalender',
                            labelNav: 'Kalendernavigation',
                            labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
                          }"
                          :start-weekday="1"
                          show-decade-nav
                          locale="de"
                          aria-controls="startdatum"
                          placeholder="Datum auswählen"
                          @input="
                            getPruefpositionData(
                              berichtData.Startdatum,
                              berichtData.Enddatum
                            )
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                      v-if="
                        berichtData.Bericht_Art === 'Bericht' ||
                        berichtData.Bericht_Art === 'Abschlussbericht'
                      "
                    >
                      <b-form-group label="Enddatum" label-for="enddatum">
                        <b-form-datepicker
                          id="enddatum"
                          v-model="berichtData.Enddatum"
                          v-bind="{
                            labelPrevDecade: 'Vorheriges Jahrzehnt',
                            labelPrevYear: 'Vorheriges Jahr',
                            labelPrevMonth: 'Vorheriger Monat',
                            labelCurrentMonth: 'Aktueller Monat',
                            labelNextMonth: 'Nächster Monat',
                            labelNextYear: 'Nächstes Jahr',
                            labelNextDecade: 'Nächstes Jahrzehnt',
                            labelToday: 'Heute',
                            labelSelected: 'Ausgewähltes Datum',
                            labelNoDateSelected: 'Kein Datum gewählt',
                            labelCalendar: 'Kalender',
                            labelNav: 'Kalendernavigation',
                            labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
                          }"
                          :start-weekday="1"
                          show-decade-nav
                          locale="de"
                          aria-controls="enddatum"
                          placeholder="Datum auswählen"
                          @input="
                            getPruefpositionData(
                              berichtData.Startdatum,
                              berichtData.Enddatum
                            )
                          "
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr />

            <!-- Items Section -->
            <b-card-body
              class="tagesbericht-padding form-item-section"
              v-if="!noPruefpositions"
            >
              <b-row>
                <b-col cols="12">
                  <h4>Auswertung aller Prüfpositionen</h4>
                </b-col>
                <b-col cols="12">
                  <b-row>
                    <!-- Geprüfte Teile gesamt -->
                    <b-col cols="12" lg="3">
                      <b-form-group
                        label="Geprüfte Teile gesamt"
                        :label-for="`gepruefte-teile-gesamt`"
                      >
                        <b-form-input
                          v-model="berichtDataGesamt.Teile_Gesamt"
                          type="number"
                          class="mb-2"
                          :id="`gepruefte-teile-gesamt`"
                          :name="`gepruefte-teile-gesamt`"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Nachgearbeitete Teile gesamt -->
                    <b-col cols="12" lg="3">
                      <b-form-group
                        label="Nachgearbeitete Teile gesamt"
                        :label-for="`nachgearbeitete-teile-gesamt`"
                      >
                        <b-form-input
                          v-model="berichtDataGesamt.Teile_Nachgearbeitet"
                          type="number"
                          class="mb-2"
                          :id="`nachgearbeitete-teile-gesamt`"
                          :name="`nachgearbeitete-teile-gesamt`"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- Teile I.O -->
                <b-col cols="12" lg="3">
                  <b-form-group
                    label="Teile I.O"
                    :label-for="`teile-io-gesamt`"
                  >
                    <b-form-input
                      v-model="berichtDataGesamt.Teile_IO"
                      type="number"
                      class="mb-2"
                      :id="`teile-io-gesamt`"
                      :name="`teile-io-gesamt`"
                    />
                  </b-form-group>
                </b-col>

                <!-- Teile N.I.O -->
                <b-col cols="12" lg="3">
                  <b-form-group
                    label="Teile N.I.O"
                    :label-for="`teile-nio-gesamt`"
                  >
                    <b-form-input
                      v-model="berichtDataGesamt.Teile_NIO"
                      type="number"
                      class="mb-2"
                      :id="`teile-nio-gesamt`"
                      :name="`teile-nio-gesamt`"
                    />
                  </b-form-group>
                </b-col>

                <!-- Teile Nachgearbeitet I.O -->
                <b-col cols="12" lg="3">
                  <b-form-group
                    label="Teile nachgearbeitet I.O"
                    :label-for="`teile-nachgearbeitet-io-gesamt`"
                  >
                    <b-form-input
                      v-model="berichtDataGesamt.Teile_Nachgearbeitet_IO"
                      type="number"
                      class="mb-2"
                      :id="`teile-nachgearbeitet-io-gesamt`"
                      :name="`teile-nachgearbeitet-io-gesamt`"
                    />
                  </b-form-group>
                </b-col>

                <!-- Teile Nachgearbeitet N.I.O -->
                <b-col cols="12" lg="3">
                  <b-form-group
                    label="Teile nachgearbeitet N.I.O"
                    :label-for="`teile-nachgearbeitet-nio-gesamt`"
                  >
                    <b-form-input
                      v-model="berichtDataGesamt.Teile_Nachgearbeitet_NIO"
                      type="number"
                      class="mb-2"
                      :id="`teile-nachgearbeitet-nio-gesamt`"
                      :name="`teile-nachgearbeitet-nio-gesamt`"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-row>
                    <!-- Arbeitszeit gesamt -->
                    <b-col cols="12" lg="3">
                      <b-form-group
                        label="Arbeitszeit gesamt"
                        :label-for="`arbeitszeit-gesamt`"
                      >
                        <b-input-group append="Stunden" class="mb-2">
                          <b-form-input
                            v-model="berichtDataGesamt.Arbeitszeit_Gesamt"
                            :id="`arbeitszeit-gesamt`"
                            :name="`arbeitszeit-gesamt`"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <h4>Auswertung der einzelnen Prüfpositionen</h4>
                <b-row
                  v-for="(pruefposition, index) in pruefpositionen"
                  :key="pruefposition.id"
                  ref="row"
                  class="pb-2"
                  v-if="pruefposition.Mitarbeiterleistungen.length > 0"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <!-- Pruefposition -->
                        <b-col cols="12">
                          <b-form-group
                            label="Prüfposition"
                            :label-for="`pruefposition-titel-${index}`"
                          >
                            <b-form-input
                              v-model="pruefposition.Titel"
                              class="mb-2"
                              :id="`pruefposition-titel-${index}`"
                              :name="`pruefposition-titel-${index}`"
                            />
                          </b-form-group>
                        </b-col>

                        <!-- Pruefposition Status -->
                        <b-col cols="12">
                          <b-form-group
                            label="Status"
                            :label-for="`pruefposition-status-${index}`"
                          >
                            <b-badge
                              :variant="
                                makeStatusObject(pruefposition.Status).color
                              "
                              class="mb-50"
                            >
                              {{ makeStatusObject(pruefposition.Status).label }}
                            </b-badge>
                          </b-form-group>
                        </b-col>

                        <b-col cols="12">
                          <b-row>
                            <!-- Geprüfte Teile gesamt -->
                            <b-col cols="12" lg="3">
                              <b-form-group
                                label="Geprüfte Teile gesamt"
                                :label-for="`gepruefte-teile-${index}`"
                              >
                                <b-form-input
                                  v-model="pruefposition.Teile_Gesamt"
                                  type="number"
                                  class="mb-2"
                                  :id="`gepruefte-teile-${index}`"
                                  :name="`gepruefte-teile-${index}`"
                                />
                              </b-form-group>
                            </b-col>
                            <!-- Nachgearbeitete Teile gesamt -->
                            <b-col cols="12" lg="3">
                              <b-form-group
                                label="Nachgearbeitete Teile gesamt"
                                :label-for="`nachgearbeitete-teile-${index}`"
                              >
                                <b-form-input
                                  v-model="pruefposition.Teile_Nachgearbeitet"
                                  type="number"
                                  class="mb-2"
                                  :id="`nachgearbeitete-teile-${index}`"
                                  :name="`nachgearbeitete-teile-${index}`"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>

                        <!-- Teile I.O -->
                        <b-col cols="12" lg="3">
                          <b-form-group
                            label="Teile I.O"
                            :label-for="`teile-io-${index}`"
                          >
                            <b-form-input
                              v-model="pruefposition.Teile_IO"
                              type="number"
                              class="mb-2"
                              :id="`teile-io-${index}`"
                              :name="`teile-io-${index}`"
                            />
                          </b-form-group>
                        </b-col>

                        <!-- Teile N.I.O -->
                        <b-col cols="12" lg="3">
                          <b-form-group
                            label="Teile N.I.O"
                            :label-for="`teile-nio-${index}`"
                          >
                            <b-form-input
                              v-model="pruefposition.Teile_NIO"
                              type="number"
                              class="mb-2"
                              :id="`teile-nio-${index}`"
                              :name="`teile-nio-${index}`"
                            />
                          </b-form-group>
                        </b-col>

                        <!-- Teile Nachgearbeitet I.O -->
                        <b-col cols="12" lg="3">
                          <b-form-group
                            label="Teile nachgearbeitet I.O"
                            :label-for="`teile-nachgearbeitet-io-${index}`"
                          >
                            <b-form-input
                              v-model="pruefposition.Teile_Nachgearbeitet_IO"
                              type="number"
                              class="mb-2"
                              :id="`teile-nachgearbeitet-io-${index}`"
                              :name="`teile-nachgearbeitet-io-${index}`"
                            />
                          </b-form-group>
                        </b-col>

                        <!-- Teile Nachgearbeitet N.I.O -->
                        <b-col cols="12" lg="3">
                          <b-form-group
                            label="Teile nachgearbeitet N.I.O"
                            :label-for="`teile-nachgearbeitet-nio-${index}`"
                          >
                            <b-form-input
                              v-model="pruefposition.Teile_Nachgearbeitet_NIO"
                              type="number"
                              class="mb-2"
                              :id="`teile-nachgearbeitet-nio-${index}`"
                              :name="`teile-nachgearbeitet-nio-${index}`"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="12">
                          <b-row>
                            <!-- Arbeitszeit gesamt -->
                            <b-col cols="12" lg="3">
                              <b-form-group
                                label="Arbeitszeit gesamt"
                                :label-for="`arbeitszeit-gesamt-${index}`"
                              >
                                <b-input-group append="Stunden" class="mb-2">
                                  <b-form-input
                                    v-model="pruefposition.Arbeitszeit"
                                    :id="`arbeitszeit-gesamt-${index}`"
                                    :name="`arbeitszeit-gesamt-${index}`"
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="12">
                          <b-row>
                            <!-- Abweichendes Fehlerbild -->
                            <b-col cols="12" lg="4">
                              <b-form-group
                                label="Abweichendes Fehlerbild"
                                :label-for="`abweichendes-fehlerbild-${index}`"
                              >
                                <b-form-textarea
                                  v-model="
                                    pruefposition.Abweichendes_Fehlerbild
                                  "
                                  class="mb-2"
                                  rows="4"
                                  placeholder="Bei abweichendem Fehlerbild, bitte kurz beschreiben"
                                  :id="`abweichendes-fehlerbild-${index}`"
                                  :name="`abweichendes-fehlerbild-${index}`"
                                />
                              </b-form-group>
                            </b-col>
                            <!-- Tätigkeitsmerkmale -->
                            <b-col cols="12" lg="4">
                              <b-form-group
                                label="Tätigkeitsmerkmale"
                                :label-for="`taetigkeitsmerkmale-${index}`"
                              >
                                <b-form-textarea
                                  v-model="pruefposition.Taetigkeitsmerkmale"
                                  class="mb-2"
                                  rows="4"
                                  placeholder="Kurze beschreibung der Tätigkeit"
                                  :id="`taetigkeitsmerkmale-${index}`"
                                  :name="`taetigkeitsmerkmale-${index}`"
                                />
                              </b-form-group>
                            </b-col>
                            <!-- Besonderheiten -->
                            <b-col cols="12" lg="4">
                              <b-form-group
                                label="Besonderheiten"
                                :label-for="`besonderheiten-${index}`"
                              >
                                <b-form-textarea
                                  v-model="pruefposition.Besonderheiten"
                                  class="mb-2"
                                  rows="4"
                                  placeholder="Kurze Beschreibung von Besonderheiten (z.B. Stillstandzeiten, abweichende Prüfkriterien, etc.)"
                                  :id="`besonderheiten-${index}`"
                                  :name="`besonderheiten-${index}`"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="12">
                          <b-row>
                            <!-- Prüfposition abgeschlossen -->
                            <b-col cols="12" lg="4">
                              <b-form-checkbox
                                v-model="pruefposition.Beendet"
                                :id="`pruefposition-abgeschlossen-${index}`"
                                :name="`pruefposition-abgeschlossen-${index}`"
                                >Prüfposition abgeschlossen</b-form-checkbox
                              >
                            </b-col>
                            <!-- Prüfposition ruht -->
                            <b-col
                              cols="12"
                              lg="4"
                              v-if="!pruefposition.Beendet"
                            >
                              <b-form-checkbox
                                :id="`pruefposition-ruht-${index}`"
                                v-model="pruefposition.Ruht"
                                :name="`pruefposition-ruht-${index}`"
                                >Prüfposition ruht</b-form-checkbox
                              >
                            </b-col>
                            <!-- Fortsetzung Prüfposition -->
                            <b-col cols="12" lg="4" v-if="pruefposition.Ruht">
                              <b-form-group
                                label="Fortsetzung des Prüfauftrags"
                                :label-for="`pruefposition-fortsetzung-${index}`"
                              >
                                <b-form-datepicker
                                  :id="`pruefposition-fortsetzung-${index}`"
                                  :name="`pruefposition-fortsetzung-${index}`"
                                  v-model="pruefposition.Fortsetzung"
                                  v-bind="{
                                    labelPrevDecade: 'Vorheriges Jahrzehnt',
                                    labelPrevYear: 'Vorheriges Jahr',
                                    labelPrevMonth: 'Vorheriger Monat',
                                    labelCurrentMonth: 'Aktueller Monat',
                                    labelNextMonth: 'Nächster Monat',
                                    labelNextYear: 'Nächstes Jahr',
                                    labelNextDecade: 'Nächstes Jahrzehnt',
                                    labelToday: 'Heute',
                                    labelSelected: 'Ausgewähltes Datum',
                                    labelNoDateSelected: 'Kein Datum gewählt',
                                    labelCalendar: 'Kalender',
                                    labelNav: 'Kalendernavigation',
                                    labelHelp:
                                      'Monat/Jahr mit Pfeiltasten ändern',
                                  }"
                                  :start-weekday="1"
                                  show-decade-nav
                                  locale="de"
                                  aria-controls="pruefposition-fortsetzung"
                                  placeholder="Datum auswählen"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <!-- Einzelleistungen -->
                        <b-col
                          cols="12"
                          class="mt-2"
                          v-if="
                            pruefposition.Einzelleistungen &&
                            pruefposition.Mitarbeiterleistungen.length > 0
                          "
                        >
                          <b-form-group
                            label="Mitarbeiterleistungen"
                            :label-for="`mitarbeiterleistungen-${index}`"
                          >
                            <b-table
                              :items="pruefposition.Mitarbeiterleistungen"
                              :fields="mitarbeiterleistungenFields"
                              :per-page="100"
                              :current-page="1"
                              sort-by="Datum"
                              striped
                              responsive
                              class="mb-0"
                              :id="`mitarbeiterleistungen-${index}`"
                            >
                              <!-- Column: Mitarbeiter -->
                              <template #cell(Mitarbeiter)="data">
                                {{ data.item.Mitarbeiter.Vorname }}
                                {{ data.item.Mitarbeiter.Nachname }}
                              </template>

                              <!-- Column: Datum -->
                              <template #cell(Datum)="data">
                                {{
                                  new Date(
                                    data.item.Arbeitsbeginn
                                  ).toLocaleDateString('de-DE', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                  })
                                }}
                              </template>

                              <!-- Column: Arbeitszeit -->
                              <template #cell(Arbeitszeit)="data">
                                <p class="m-0 text-nowrap text-center">
                                  {{
                                    new Date(
                                      data.item.Arbeitsbeginn
                                    ).toLocaleDateString('de-DE', {
                                      day: '2-digit',
                                      month: '2-digit',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })
                                  }}
                                  Uhr
                                </p>
                                <p class="m-0 text-center">bis</p>
                                <p class="m-0 text-nowrap text-center">
                                  {{
                                    new Date(
                                      data.item.Arbeitsende
                                    ).toLocaleDateString('de-DE', {
                                      day: '2-digit',
                                      month: '2-digit',
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })
                                  }}
                                  Uhr
                                </p>

                                <hr class="m-0 my-10" />
                                <p class="m-0 text-center font-weight-bold">
                                  =
                                  {{
                                    convertToIndustrialHours(
                                      data.item.Arbeitszeit
                                    )
                                  }}
                                  ih
                                </p>
                              </template>

                              <!-- Column: Ausgeführt -->
                              <template #cell(Taetigkeit_Ausgefuehrt)="data">
                                <b-form-checkbox
                                  disabled
                                  v-model="data.item.Taetigkeit_Ausgefuehrt"
                                />
                              </template>
                            </b-table>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          class="mt-2"
                          v-if="
                            pruefposition.Diagramm &&
                            pruefposition.Mitarbeiterleistungen.length > 0
                          "
                        >
                          <b-form-group
                            label="Diagramm"
                            :label-for="`diagramm-${index}`"
                          >
                            <vue-apex-charts
                              type="donut"
                              height="350"
                              :options="chartOptions"
                              :series="[
                                pruefposition.Teile_IO,
                                pruefposition.Teile_NIO,
                                pruefposition.Teile_Nachgearbeitet_IO,
                                pruefposition.Teile_Nachgearbeitet_NIO,
                              ]"
                              :id="`diagramm-${index}`"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                        <feather-icon
                          :id="`form-item-settings-icon-${index}`"
                          size="16"
                          icon="SettingsIcon"
                          class="cursor-pointer"
                        />

                        <!-- Setting Item Form -->
                        <b-popover
                          :ref="`form-item-settings-popover-${index}`"
                          :target="`form-item-settings-icon-${index}`"
                          triggers="click"
                          placement="lefttop"
                        >
                          <b-form @submit.prevent>
                            <b-row>
                              <!-- Einzelleistungen anzeigen -->
                              <b-col cols="12" class="mb-1">
                                <b-form-checkbox
                                  :id="`einzelleistungen-anzeigen-${index}`"
                                  v-model="pruefposition.Einzelleistungen"
                                  :name="`einzelleistungen-anzeigen-${index}`"
                                  @change="initTrHeight()"
                                  >Einzelleistungen anzeigen</b-form-checkbox
                                >
                              </b-col>

                              <b-col cols="12">
                                <!-- Diagramm anzeigen -->
                                <b-form-checkbox
                                  :id="`diagramm-anzeigen-${index}`"
                                  v-model="pruefposition.Diagramm"
                                  :name="`diagramm-anzeigen-${index}`"
                                  @change="initTrHeight()"
                                  >Diagramm anzeigen</b-form-checkbox
                                >
                              </b-col>

                              <b-col
                                cols="12"
                                class="d-flex justify-content-center mt-1"
                              >
                                <b-button
                                  variant="outline-primary"
                                  @click="
                                    () => {
                                      $refs[
                                        `form-item-settings-popover-${index}`
                                      ][0].$emit('close');
                                    }
                                  "
                                >
                                  Schließen
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        </b-popover>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>

            <b-card-body v-if="noPruefpositions">
              <b-row class="ml-1" ref="noData">
                <b-col cols="12">
                  <h4>Es gibt keine Daten für diesen Zeitraum</h4>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="tagesbericht-spacing" />

            <!-- Note -->
            <b-card-body class="tagesbericht-padding pt-0">
              <span class="font-weight-bold">Anmerkung: </span>
              <b-form-textarea
                v-model="berichtData.Anmerkung"
                placeholder="Zusätzliche Anmerkung zum Bericht"
              />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="tagesbericht-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: DOwnload -->
          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
          >
            Vorschau
          </b-button> -->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            block
            :disabled="noPruefpositions"
            @click="updateBericht()"
          >
            Änderungen Speichern
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from '@/layouts/components/TempoRatioLogo.vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import {
  BRow,
  BBadge,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BTable,
  VBToggle,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    BRow,
    BBadge,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BTable,
    vSelect,
    Logo,
    VueApexCharts,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      orderData: {
        Auftragsnummer: null,
        Ansprechpartner_ID: 0,
        Kunde: {
          Firma: null,
          Anschrift: {
            Stadt: null,
          },
          Ansprechpartner: [
            {
              id: 0,
              Vorname: null,
              Nachname: null,
            },
          ],
        },
      },
      pruefpositionen: [],
      berichtData: {},
      mitarbeiterleistungenFields: [
        {
          key: 'Mitarbeiter',
          label: 'Mitarbeiter',
          sortable: true,
        },
        {
          key: 'Datum',
          label: 'Datum',
          sortable: true,
        },
        {
          key: 'Arbeitszeit',
          label: 'Arbeitszeit',
          sortable: true,
        },
        {
          key: 'Teile_IO',
          label: 'I.O.',
          sortable: true,
        },
        {
          key: 'Teile_NIO',
          label: 'N.I.O.',
          sortable: true,
        },
        {
          key: 'Teile_Nachgearbeitet_IO',
          label: 'Nachg. I.O.',
          sortable: true,
        },
        {
          key: 'Teile_Nachgearbeitet_NIO',
          label: 'Nachg. N.I.O.',
          sortable: true,
        },
        {
          key: 'Teile_Gesamt',
          label: 'Gesamt',
          sortable: true,
        },
        {
          key: 'Taetigkeit_Ausgefuehrt',
          label: 'Ausgeführt',
          sortable: true,
        },
      ],
      chartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#28c76f', '#ea5455', '#206898', '#ff9f43'],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`;
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}%`;
                  },
                },
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: '1.5rem',
                  label: 'Geprüfte Teile',
                  formatter(w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        labels: [
          'Teile I.O.',
          'Teile N.I.O.',
          'Teile Nachgearbeitet I.O.',
          'Teile Nachgearbeitet N.I.O.',
        ],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    auftragsort() {
      return this.orderData.Abweichender_Auftragsort?.Stadt &&
        this.orderData.Abweichender_Auftragsort?.Stadt !== '-'
        ? this.orderData.Abweichender_Auftragsort.Stadt
        : this.orderData.Kunde.Anschrift.Stadt;
    },
    auftragsstatus() {
      return this.makeStatusObject(this.orderData.Status);
    },
    ansprechpartner() {
      return this.orderData.Kunde.Ansprechpartner.filter(
        ansprechpartner =>
          ansprechpartner.id === this.orderData.Ansprechpartner_ID
      )[0];
    },
    noPruefpositions() {
      let leistungsCount = 0;
      this.pruefpositionen.forEach(pruefposition => {
        leistungsCount += pruefposition.Mitarbeiterleistungen.length;
      });

      return leistungsCount === 0;
    },
    berichtDataGesamt() {
      let Teile_Gesamt = null;
      let Teile_Nachgearbeitet = null;
      let Teile_IO = null;
      let Teile_NIO = null;
      let Teile_Nachgearbeitet_IO = null;
      let Teile_Nachgearbeitet_NIO = null;
      let Arbeitszeit_Gesamt = '00:00';

      if (this.noPruefpositions) {
        return {
          Teile_Gesamt,
          Teile_Nachgearbeitet,
          Teile_IO,
          Teile_NIO,
          Teile_Nachgearbeitet_IO,
          Teile_Nachgearbeitet_NIO,
          Arbeitszeit_Gesamt,
        };
      }

      this.pruefpositionen.forEach(pruefposition => {
        Teile_Gesamt += pruefposition.Teile_Gesamt;
        Teile_Nachgearbeitet += pruefposition.Teile_Nachgearbeitet;
        Teile_IO += pruefposition.Teile_IO;
        Teile_NIO += pruefposition.Teile_NIO;
        Teile_Nachgearbeitet_IO += pruefposition.Teile_Nachgearbeitet_IO;
        Teile_Nachgearbeitet_NIO += pruefposition.Teile_Nachgearbeitet_NIO;

        // Set Arbeitszeit
        const calculateArbeitszeit = arbeitszeit => {
          let [totalHours, totalMinutes] = arbeitszeit.split(':');
          const [hours, minutes] = pruefposition.Arbeitszeit.split(':');
          totalHours = +totalHours + +hours;
          totalMinutes = +totalMinutes + +minutes;

          if (totalMinutes >= 60) {
            totalMinutes %= 60;
            totalHours += Math.floor(totalMinutes / 60);
          }
          return `${totalHours < 10 ? `0${totalHours}` : totalHours}:${
            totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes
          }`;
        };
        Arbeitszeit_Gesamt = calculateArbeitszeit(Arbeitszeit_Gesamt);
      });

      return {
        Teile_Gesamt,
        Teile_Nachgearbeitet,
        Teile_IO,
        Teile_NIO,
        Teile_Nachgearbeitet_IO,
        Teile_Nachgearbeitet_NIO,
        Arbeitszeit_Gesamt,
      };
    },
  },
  mounted() {
    this.initTrHeight();
  },
  beforeRouteEnter(to, from, next) {
    // Initialize Breadcrubs
    to.meta.pageTitle = `Bericht bearbeiten`;
    to.meta.breadcrumb[1] = {
      text: `Auftrag`,
      active: true,
    };
    next();
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight);
    await this.getBerichtData();

    // Change Breadcrumb Text
    this.$route.meta.pageTitle = `Bericht #${this.berichtData.Tagesberichtnummer} bearbeiten`;
    this.$route.meta.breadcrumb[1] = {
      text: `Auftrag #${this.orderData.Auftragsnummer}`,
      to: { path: `/auftrag/details/${this.orderData.id}` },
    };
    this.$router.replace({ query: { temp: Date.now() } });
    this.$router.replace({ query: { temp: undefined } });

    this.initTrHeight();
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    convertToIndustrialHours(timeString) {
      const timeParts = timeString.split(':');
      const hours = +timeParts[0];
      const minutes = (+timeParts[1] / 60) * 100;
      return `${hours},${minutes}`;
    },
    makeStatusObject(status) {
      switch (true) {
        case status === 'Schwebend':
          return { color: 'secondary', label: 'Warte auf Freigabe' };
        case status === 'Bestaetigt':
          return { color: 'info', label: 'Bestätigt' };
        case status === 'Bereit':
          return { color: 'success', label: 'Bereit' };
        case status === 'Laufend':
          return { color: 'dark', label: 'Laufend' };
        case status === 'Beendet':
          return { color: 'primary', label: 'Beendet' };
        case status === 'Ruht':
          return { color: 'warning', label: 'Ruht' };
        case status === 'Abgebrochen':
          return { color: 'danger', label: 'Abgebrochen' };
        default:
          return { color: 'secondary', label: 'Warte auf Freigabe' };
      }
    },
    removeItem(index) {
      this.pruefpositionen.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        if (this.noPruefpositions) {
          this.trSetHeight(29);
        } else {
          this.trSetHeight(this.$refs.form.scrollHeight);
        }
      });
    },
    async updateBericht() {
      const data = { ...this.berichtData, ...this.berichtDataGesamt };
      data.Pruefpositionen = [];
      data.Auftrag = { id: this.orderData.id };
      data.Arbeitszeit_Gesamt = `${data.Arbeitszeit_Gesamt}`;
      data.Auftragsstatus = this.orderData.Status;

      const pruefpositionenDaten = [];
      this.pruefpositionen.forEach(pruefposition => {
        if (pruefposition.Mitarbeiterleistungen.length === 0) {
          return;
        }
        data.Pruefpositionen.push({ id: pruefposition.id });
        pruefpositionenDaten.push(pruefposition);
      });

      data.Gespeicherte_Pruefpositionsdaten =
        JSON.stringify(pruefpositionenDaten);

      await this.$http
        .put(`/tagesberichte/${this.berichtData.id}`, { data })
        .then(response => {
          this.$router.push({
            name: 'tagesbericht-vorschau',
            params: { id: response.data.data.id },
          });
        })
        .catch(err => {
          console.log(err.response.data.error.message);
        });
    },
    async getOrderData(id) {
      // Fetch Order Data
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['id', 'Status', 'Auftragsnummer', 'Ansprechpartner_ID'],
          populate: {
            Abweichender_Auftragsort: {
              fields: ['Stadt'],
            },
            Tagesberichte: {
              fields: 'id',
            },
            Kunde: {
              fields: ['Firma'],
              populate: {
                Ansprechpartner: {
                  fields: ['Vorname', 'Nachname'],
                },
                Anschrift: {
                  fields: ['Stadt'],
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/auftraege/${id}?${query}`)
        .then(response => {
          this.orderData = response.data.data;
        })
        .catch(err => console.log(err.response.data.error.message));
    },
    async getPruefpositionData(startDate, endDate, id) {
      if (!endDate) {
        endDate = startDate;
      }
      if (!startDate) {
        startDate = endDate;
      }
      let modifiedEndDate = new Date(endDate);
      endDate = new Date(
        modifiedEndDate.setDate(modifiedEndDate.getDate() + 1)
      );

      // Fetch Order Data
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['id'],
          populate: {
            Pruefpositionen: {
              fields: ['Bezeichnung', 'Status'],
              populate: {
                Mitarbeiterleistungen: {
                  filters: {
                    $and: [
                      {
                        Arbeitsbeginn: {
                          $gte: startDate,
                        },
                      },
                      {
                        Arbeitsbeginn: {
                          $lte: endDate,
                        },
                      },
                    ],
                  },
                  fields: [
                    'Arbeitsbeginn',
                    'Arbeitszeit',
                    'Teile_IO',
                    'Teile_NIO',
                    'Teile_Nachgearbeitet_IO',
                    'Teile_Nachgearbeitet_NIO',
                    'Teile_Gesamt',
                    'Sonstiges',
                    'Taetigkeit_Ausgefuehrt',
                  ],
                  populate: {
                    Mitarbeiter: {
                      fields: ['Vorname', 'Nachname'],
                    },
                  },
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/auftraege/${id}?${query}`)
        .then(response => {
          this.pruefpositionen = response.data.data.Pruefpositionen;
          this.pruefpositionen =
            this.berichtData.Gespeicherte_Pruefpositionsdaten;
        })
        .catch(err => console.log(err.response.data.error.message));
      this.initTrHeight();
    },
    async getBerichtData() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['*'],
          populate: {
            Auftrag: {
              fields: ['id'],
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/tagesberichte/${this.$route.params.id}?${query}`)
        .then(async response => {
          this.berichtData = response.data.data;
          await this.getOrderData(this.berichtData.Auftrag.id);
          await this.getPruefpositionData(
            this.berichtData.Startdatum,
            this.berichtData.Enddatum,
            this.berichtData.Auftrag.id
          );
        })
        .catch(err => console.log(err.response.data.error.message));
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.tagesbericht-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/tagesberichte.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
